<template>
  <div class="home-header"
       :class="{'showBg':showBg,'blackBg':!showBg,'homeActive':homeActive}"

       @mouseover="isLogo=false"
       @mouseout="isLogo=true"
  >
      <div class="home-nav">
          <div class="navbar-container">
            <router-link to="/home">
              <img v-if="isLogo" src="../../assets/logo.png" alt="">
              <img v-else src="../../assets/black-logo.png">
            </router-link>
          </div>
          <div class="menu-box">
              <div class="menu-item plan-hover">
                <router-link to="/plan">行业方案</router-link>
                <div class="plan-content">
                    <div class="content-box"
                         v-show="showPage" @click="clicklist"
                    >
                      <div class="plan-item" v-for="(item,index) in planList" :key="index">
                        <h3>{{item.solutionTypeName}}</h3>
                        <div class="item-content" v-for="(info,idx) in item.solutionList" :key="info.id+idx">
                          <router-link :to="'/planDetail?id='+info.id">
                            <div class="plan-info">
                              <h3>{{info.solutionName}}</h3>
                              <div style="color: #797979;">
                                {{info.solutionDesc}}
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>

                </div>
              </div>
              <div class="menu-item goods-hover">
                <router-link to="/goodsList">技术产品</router-link>
                <div class="goods-content"
                     v-show="showPage" @click="clicklist"
                >
                    <div class="content-box">
                        <div class="plan-item"
                             v-for="(item,index) in goodsList"
                             :key="index"
                        >
                          <h3 v-if="item.children&&item.children.length">{{item.label}}</h3>
                          <div v-else @click="goDetail(item)">{{item.label}}</div>
                          <div class="goods-box">
                            <div class="item-content"
                                 v-if="item.children"
                                 v-for="(item1,index1) in item.children"
                                 :key="index1"
                            >
                              <h3 v-if="item1.children&&item1.children.length">{{item1.label}}</h3>
                              <div v-else @click="goDetail(item1)">{{item1.label}}</div>
                              <div class="goods-item"
                                   v-if="item1.children"
                                   v-for="(item2,index2) in item1.children"
                                   :key="index2"
                              >
                                <div @click="goDetail(item2)">{{item2.label}}</div>
                              </div>
                            </div>
                          </div>

                        </div>
                    </div>
                </div>
              </div>
              <div class="menu-item project-hover">
                <router-link to="/projectList">项目案例</router-link>
                <div class="project-content"
                     v-show="showPage" @click="clicklist"
                >
                  <div class="content-box">
                    <div class="plan-item" v-for="(item,index) in projectList" :key="index">
                      <h3>{{item.caseTypeName}}</h3>
                      <div class="item-content" v-for="(info,idx) in item.caseList" :key="info.id+idx">
                        <router-link :to="'/projectDetail?id='+info.id">
                          <div class="plan-info">
                            <h3>{{info.caseName}}</h3>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="menu-item anRuan-hover">
                关于安软
                <div class="anRuan-content"
                     v-show="showPage" @click="clicklist"
                >
                    <div class="anRuan-info">
                          <div class="info-item">
                              <h3>安软简介</h3>
                              <div>
                                <router-link to="/companyProfile?type=1">简介概述</router-link>
                              </div>
                              <div>
                                <router-link to="/companyProfile?type=2">战略主轴</router-link>
                              </div>
                              <div>
                                <router-link to="/companyProfile?type=3">研究与应用</router-link>
                              </div>
                              <div>
                                <router-link to="/companyProfile?type=4">发展历程</router-link>
                              </div>
                          </div>
                          <div class="info-item">
                              <h3>新闻资讯</h3>
                              <div>
                                <router-link to="/news">官网新闻</router-link>
                              </div>
                      </div>
                          <div class="info-item">
                              <h3>加入安软</h3>
                              <div>
                                <router-link to="/recruitment">人才招聘</router-link>
                              </div>
                      </div>
                    </div>

                </div>
              </div>
              <div class="menu-item consulting-hover">
                合作咨询
                <div class="consulting">
                   <div>
                     <router-link to="/consulting?type=1">想代理</router-link>
                   </div>
                    <div>
                      <router-link to="/consulting?type=2">想合作</router-link>
                    </div>
                   <div>
                     <router-link to="/consulting?type=3">资料下载</router-link>
                   </div>

                </div>
              </div>
          </div>
          <div class="num" style="margin-left: 440px;font-size: 14px">
            立即咨询：0755-26727202
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "home-header",
  data(){
    return {
      planList:[],
      projectList:[],
      goodsList:[],
      showPage:true,
      showBg:true,
      isLogo:true,
      homeActive:false
    }
  },
  created() {
    this.getPlanList();
    this.getProjectList();
    this.getGoodsList();
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll)
  },
  watch:{
    '$route':{
      handler:function (val){
        if(val.name!='Home'){
          this.showBg=false
        }else {
          this.showBg=true
        }
      },
      immediate:true
    }
  },
  methods:{
    handleScroll(){
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          if(this.$route.name=='Home'){
              if(scrollTop>0){
                this.homeActive=true
                this.isLogo=false
              }else {
                this.homeActive=false
                this.isLogo=true
              }
          }

    },
    getPlanList(){
      this.$get('/website/solution/group/list').then((res)=>{
        this.planList=res.rows;
      }).catch((err)=>{
        this.msgError(err.msg);
      })
    },
    getProjectList(){
      this.$get('/website/case/group/list').then((res)=>{
          this.projectList=res.rows;
      }).catch((err)=>{
        this.msgError(err.msg)
      })
    },
    getGoodsList(){
      this.$get('/website/product/category/treeselect').then((res)=>{
        this.goodsList=res.data;
      }).catch((err)=>{
        this.msgError(err.msg);
      })
    },
    goDetail(item){
      if(item.styleType==1){
        this.$router.push({
          name:'night',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==2){
        this.$router.push({
          name:'eyes',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==3){
        this.$router.push({
          name:'engine',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==4){
        this.$router.push({
          name:'parsing',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==5){
        this.$router.push({
          name:'algorithm',
          query:{
            id:item.id
          }
        })
      }
    },
    clicklist(){
     this.showPage=false;
     setTimeout(()=>{
       this.showPage=true;
     },500)
    }
  }
}
</script>
<style scoped lang="scss">
.showBg.home-header{
  background:none;
}
.home-header:hover,.homeActive{
  background: #fff!important;
  .menu-box .menu-item{
    color: black!important;
  }
   .menu-box .menu-item > a{
     color: black!important;
   }
  .num{
    color: black !important;
  }
}
.blackBg.home-header{
  background: black;
}
.home-header{
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  width: 100%;
  background: black;
  height: 64px;
  background: inherit;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  z-index: 999;
  .home-nav{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 64px;
    line-height: 64px;
    .navbar-container{
      margin-right: 30px;
      img{
        margin-top: 8px;
        width: 48px;
        height: 41px;
      }
    }
    .menu-box{
        .menu-item{
          display: inline-block;
          position: relative;
          padding: 0 20px;
          font-size: 14px;
          color: #fff;
          font-weight: 400;
          cursor: pointer;
          bottom:2px;
          border-bottom: 2px solid transparent;
          >a{
            text-decoration: none;
            color: #fff;
          }
        }
        .menu-item:hover{
          border-color: black;
        }
        .consulting{
          position: absolute;
          max-height: 0px;
          overflow: hidden;
          z-index: 999;
          font-size: 14px;
          width: 100%;
          background: #fff;
          color: black;
          left: 0px;
          top: 67px;
          text-align: center;
          opacity: 0;
          transition: all 0.5s;
          >div{
            height: 40px;
            line-height: 40px;
            a{
              text-decoration:none;
              color: black;
            }
          }
        }
        .consulting-hover:hover{
        .consulting{
          max-height: 200px;
          opacity: 1;
          transition: all 1s;
        }
        }
        .project-content,.plan-content,.goods-content{
          position: fixed;
          left: 0;
          top: 64px;
          width: 100%;
          max-height: 0px;
          background: #fff;
          overflow: hidden;
          opacity: 0;
          transition: all 0.5s;
          color: black;
          .content-box{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .plan-item{
              width: 570px;
              >h3{
                border-bottom: 1px solid black;
                height: 30px;
                line-height: 30px;
                font-size: 18px;
                font-weight: bold;
              }
              .item-content{
                display: inline-block;
                margin-right: 20px;
                line-height: 1;
                font-size: 14px;
                a{
                  color: black;
                  text-decoration:none;
                }
                .plan-info{
                  >h3{
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                    font-weight: bold;
                    margin: 10px 0;
                  }
                }
              }

            }
            .plan-item:nth-child(2n){
              margin-left: 60px;
            }
          }
        }
        .goods-content{
          .content-box{
            .plan-item:first-of-type{
              width: 1200px;
            }
            .plan-item{
              margin-left: 0!important;
            }
            .plan-item:nth-child(3n){
              margin-left: 60px!important;
            }
          }

        }
        .project-content{
          line-height: 1;
        }
        .plan-hover:hover{
        .plan-content{
          opacity: 1;
          max-height: 1200px;
          transition: all 1s;
          overflow-y: auto;
        }
      }
        .project-hover:hover{
        .project-content{
          opacity: 1;
          max-height: 1200px;
          transition: all 1s;
          overflow-y: auto;
        }
      }
        .goods-box{
        h3{
          margin-top: 0;
        }
      }
        .goods-box{
        display: flex;
        justify-content: space-between;
        .item-content,.goods-item{
          padding: 5px 0;
        }
      }
        .goods-hover:hover{
        .goods-content{
          opacity: 1;
          max-height: 800px;
          transition: all 1s;
          overflow-y: auto;
          padding-bottom: 20px;
        }
      }
        .anRuan-content{
          position: fixed;
          left: 0;
          top: 64px;
          width: 100%;
          max-height: 0px;
          background: #fff;
          overflow: hidden;
          opacity: 0;
          transition: all 0.5s;
          .anRuan-info{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            color: black;
            font-size: 14px;
            .info-item{
              margin-right: 100px;
              h3{
                border-bottom: 1px solid black;
                padding-right: 100px;
                height: 30px;
                line-height: 30px;
                font-size: 18px;
                font-weight: bold;
              }
              >div{
                height: 40px;
                line-height: 40px;
                a{
                  color: black;
                  text-decoration:none;
                }
              }
            }
          }
          .anRuan-footer{
            width: 1200px;
            margin: 0 auto;
            color: black;
            span{
              margin-right: 100px;
            }
          }
        }
        .anRuan-hover:hover{
            .anRuan-content{
              opacity: 1;
              max-height: 600px;
              transition: all 1s;
            }
        }
    }
  }
}
</style>
